@import '../../styles/variables';

.container {
  background-color: var(--background-content);
  border-radius: 0.625rem;
  height: 100%;
  padding: 1rem;

  max-width: $layout-breakpoint-xl;
  margin-left: auto;
  margin-right: auto;

  .title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--background-content-text);
    text-transform: uppercase;
  }

  .heading {
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    .searchBar {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      .containerDate {
        display: flex;
        gap: 1rem;
        border: none;

        .inputDate {
          max-width: 9rem;
        }
      }

      .inputContainer {
        border: none;
        display: grid;
        width: 16rem;
        max-width: 100%;

        .userInput:disabled {
          color: black;
        }

        .searchIcon {
          color: var(--primary-400);
          font-weight: bold;
          font-size: 1.5rem;
          cursor: pointer;
        }
      }

      .searchContainer {
        display: flex;
        align-items: stretch;

        .searchButton {
          font-size: 0.75rem;
          font-weight: 700;
        }
      }
    }
  }
}

.paginationContainer {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.selectMenuLoadIndicator {
  margin: 1rem 0;
}
