@import '../../../../styles/variables';

.contentIntroductionContainer {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $layout-breakpoint-md) {
    flex-direction: row;
  }

  .imgContainer {
    max-width: 100%;
    height: 350px;
    object-fit: cover;
    width: 100%;

    @media screen and (min-width: $layout-breakpoint-md) {
      width: 50%;
      height: 450px;
    }
  }

  .contentIntroductionContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: var(--content-description);

    @media screen and (min-width: $layout-breakpoint-md) {
      width: 50%;
      height: 450px;
    }

    .introductionHeading {
      background: var(--primary-content);
      padding: 1rem;

      color: var(--primary-content-text);
      text-transform: uppercase;

      h5 {
        font-weight: 500;
        font-size: 1rem;
        margin-bottom: 0.4rem;
        color: var(--primary-content-text);
      }

      h4 {
        font-weight: 500;
        font-size: 1.375rem;
        color: var(--primary-content-text);
      }
    }

    .introductionInfos {
      height: 100%;
      padding: 2rem 1rem;
      max-height: 22rem;
      overflow-y: scroll;

      .heading {
        font-weight: 600;
        color: var(--primary-content);
        text-transform: uppercase;
        margin-bottom: 1rem;
      }

      h1 {
        @extend .heading;
        font-size: 2.5rem;
      }

      h2 {
        @extend .heading;
        font-size: 2rem;
      }

      h3 {
        @extend .heading;
        font-size: 1.75rem;
      }

      h4 {
        @extend .heading;
        font-size: 1.5rem;
      }

      h5 {
        @extend .heading;
        font-size: 1.25rem;
      }

      h6 {
        @extend .heading;
        font-size: 1rem;
      }

      p {
        font-size: 0.9rem;
        font-weight: 400;
        margin-bottom: 1rem;
        color: var(--content-description-text);
      }

      blockquote {
        background-color: var(--background);
        border-left: 10px solid var(--content-description-text);
        padding: 1rem;
        margin-bottom: 1rem;

        p {
          margin: 0;
        }
      }

      ul,
      ol {
        padding-left: 2rem;
        font-size: 0.9rem;
        font-weight: 400;
        color: var(--content-description-text);
        margin-bottom: 1rem;
      }

      hr {
        margin-bottom: 1rem;
        background-color: var(--background);
      }

      pre {
        background-color: var(--background);
        color: var(--content-description-text);
        padding: 1rem;
        margin-bottom: 1rem;
        white-space: pre-wrap;
      }

      a {
        margin-bottom: 1rem;
        color: var(--content-description-text);
      }

      table {
        background-color: var(--background);
        margin-bottom: 1rem;
        color: var(--table-cell-text);
        border-collapse: collapse;

        thead {
          tr {
            border-bottom: 1px solid grey;
            th {
              padding: 0.5rem;
            }
          }
        }

        tbody tr td {
          padding: 0.5rem;
        }
      }
    }

    .introductionActions {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      color: var(--primary-content);

      button {
        border: none;

        text-decoration: underline;

        font-size: 1rem;
        font-weight: 500;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }

        svg {
          font-size: 1.5rem;
          margin-right: 0.3rem;
        }
      }

      a {
        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }

        svg {
          font-size: 2rem;
          color: var(--primary-content);
        }
      }
    }
  }

  .border {
    border: 1px solid var(--primary-content);
  }
}
