.statusBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  font-size: 1rem;
}

.activeBox {
  @extend .statusBox;
  background: var(--active-status-box);
  color: var(--active-status-box-text);
}

.inactiveBox {
  @extend .statusBox;
  background: var(--inactive-status-box);
  color: var(--inactive-status-box-text);
}

.checkboxCell {
  display: flex;
  justify-content: center;
}

.checkbox {
  color: var(--primary-400);
}
