@import '../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: $layout-breakpoint-xl;
  margin-left: auto;
  margin-right: auto;
}

.sectionsContainer {
  display: flex;
  flex-direction: column;
  color: var(--content-description-text);

  h1 {
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
  }
}
