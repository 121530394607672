@import "../../styles/variables";

.container {
  padding: 1.25rem 2.5rem 4rem;
  height: 100%;

  @media screen and (max-width: $layout-breakpoint-sm) {
    padding: 1rem 1rem 4rem;
  }
}
