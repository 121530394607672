.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background: var(--footer);
  font-size: 0.6rem;
  font-weight: 500;

  padding: 1rem;

  text-align: center;

  color: var(--footer-text);
}
