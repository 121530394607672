@import '../../../../styles//variables';

.listContainer {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  gap: 1.5rem;

  @media screen and (max-width: $layout-breakpoint-xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: $layout-breakpoint-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: $layout-breakpoint-sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.noItemsListContainer {
  display: block;
  margin-top: 1rem;
  color: var(--background-content-text);
}

.paginationContainer {
  display: flex;
  justify-content: center;

  margin-top: 1.5rem;
}
