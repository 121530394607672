@import '../../../styles/variables';

.audioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $modal-content-small-size;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 1rem;

  .audioPlayerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .audioName {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .audioSubtitle {
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow: hidden;

      * {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .audioControls {
      display: flex;

      .volume {
        display: flex;
        column-gap: 1rem;
        flex: 1;
      }

      .controls {
        display: flex;
        justify-content: flex-end;
        flex: 2;
      }
    }

    .audioTrack {
      display: flex;
      column-gap: 1.5rem;
      width: 100%;
    }
  }
}

.noAudioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
}
