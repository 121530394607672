.container {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 930px) {
    flex-direction: row;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 2rem 1.25rem;

  @media screen and (min-width: 930px) {
    flex-direction: column;
  }

  .button {
    width: 60px;
    height: 40px;

    svg {
      transform: rotate(90deg);

      @media screen and (min-width: 930px) {
        transform: rotate(0deg);
      }
    }
  }
}
