.cardContainer {
  overflow: hidden;
  width: 250px;
  background: var(--card-container);
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  text-decoration: none;
  transition: opacity 300ms ease-in-out;

  .cardContent {
    display: flex;
    flex-direction: column;
    text-decoration: none;

    .completedContentImgContainer {
      position: relative;
      height: 10rem;

      .contentImgContainer {
        width: 100%;
        height: 10rem;
        object-fit: cover;
      }

      div {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background: var(--primary-content-transparent);

        svg {
          color: var(--card-completed-svg);
          font-size: 3rem;
          margin-bottom: 0.8rem;
        }

        span {
          color: var(--card-completed-svg);
          font-size: 0.8rem;
          font-weight: 600;
        }
      }
    }

    .contentName {
      background: var(--primary-content);
      text-align: center;
      padding: 0.5rem 0;

      display: flex;
      justify-content: center;
      align-items: center;

      height: 3rem;

      h5 {
        font-size: 0.9rem;
        color: var(--primary-content-text);
        font-weight: 600;
      }
    }

    .contentDescription {
      height: 9rem;
      padding: 1rem;

      .contentDescriptionText {
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;

        color: var(--card-description);

        font-weight: 400;

        * {
          font-size: 0.9rem;
        }
      }
    }
  }

  .contentActions {
    margin: 1rem;

    display: flex;
    justify-content: space-between;

    button,
    span {
      text-decoration: underline;
      font-size: 0.9rem;
      font-weight: 500;
      color: var(--card-actions);
    }

    button {
      border: none;
      background: transparent;

      display: flex;
      justify-content: center;
      align-items: center;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }

      svg {
        margin-right: 0.3rem;
        font-size: 1.2rem;
      }
    }

    .numberMedias {
      margin-left: auto;
    }
  }
}

.cardContainer:hover {
  opacity: 0.7;
}
