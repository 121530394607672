@import '../../styles/variables';

.headerContainer {
  height: 5rem;
  background-color: var(--header);
  position: sticky;
  top: 0;
  z-index: 13;

  .headerContent {
    height: 5rem;
    width: 100%;

    padding: 1.25rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .menuButtonContainer {
      flex: 1;

      .menuButton {
        border: 2px solid var(--header-text);
        border-radius: 6px;

        padding: 0.2rem;

        background: transparent;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 1.1rem;
          color: var(--header-text);
        }
      }
    }

    .imageLogoContainer {
      flex: 1;
      text-align: center;

      a {
        .imageLogo {
          height: 2.5rem;
          width: auto;
        }
      }
    }

    .userData {
      flex: 1;
      justify-content: flex-end;

      display: flex;

      .themeButton svg {
        fill: var(--header-text);
      }

      .loggedUser {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        border: none;
        background: transparent;
        color: var(--header-text);

        span {
          font-size: 1.1rem;
          font-weight: 500;

          overflow: hidden;
          line-height: 22px; //Fallback
          max-height: 44px; //Fallback

          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          display: none;

          @media screen and (min-width: $layout-breakpoint-md) {
            display: block;
            display: -webkit-box;
          }
        }

        svg {
          font-size: 2rem;
        }
      }
    }
  }
}

.menuUserName {
  display: block;

  @media screen and (min-width: $layout-breakpoint-md) {
    display: none;
  }
}
