@import '../../../../styles/variables';

.newPasswordContainer {
  width: $modal-content-small-size;
  max-width: 100%;

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
