.link {
  display: flex;
  align-items: center;

  text-decoration: none;
  color: var(--navs-text);
  font-size: 1rem;
  font-weight: 500;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  svg {
    font-size: 1.5rem;
    color: var(--navs-text);
    margin-right: .5rem;
  }
}

.activeLink {
  @extend .link;
  color: var(--primary-content);

  svg {
    color: var(--primary-content);
  }
}