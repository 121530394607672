.statusBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  font-size: 1rem;
}

.activeBox {
  @extend .statusBox;
  background: var(--active-status-box);
  color: var(--active-status-box-text);
}

.inactiveBox {
  @extend .statusBox;
  background: var(--inactive-status-box);
  color: var(--inactive-status-box-text);
}

.arrowColumn {
  display: flex;
  justify-content: center;
}

.container {
  .title {
    display: block;
    margin-bottom: 1rem;
  }

  .tableContainer {
    max-height: 18rem;
    overflow-y: scroll;

    .table {
      table-layout: fixed;

      .tableHeader {
        position: sticky;
        top: 0;
        z-index: 10;

        background-color: var(--modal-save-button);
        .tableHeadCell {
          color: var(--modal-save-button-text);
        }
      }

      td {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
