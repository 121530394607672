@import '../../styles/variables';

.homeHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    font-weight: 600;
    font-size: 1.25rem;
    color: var(--background-content-text);
    text-transform: uppercase;
  }

  .contentNameFormFilter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
}

.categoryName {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: $layout-breakpoint-sm) {
    flex-direction: column;
    align-items: start;
  }

  h1 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary-content);
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--primary-content);
    border: 0.8px solid var(--primary-content);
    border-radius: 5px;
    padding: 0.4rem 0.6rem;

    svg {
      color: var(--primary-content-text);
      font-size: 0.8rem;
      font-weight: 500;
    }

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
}
