@import '../../styles/variables';

.pdfViewer {
  min-width: fit-content;
  margin: auto;

  .documentControls {
    display: flex;
    align-items: center;

    position: sticky;

    text-align: center;
    width: fit-content;

    background-color: var(--modal-save-button);
    color: var(--modal-save-button-text);

    top: 0;
    left: 0;
    border-radius: 5px;
    margin-bottom: 2rem;
    padding: 1rem;

    z-index: 12;

    user-select: none;

    svg {
      fill: var(--modal-save-button-text);
    }

    button:disabled {
      svg {
        fill: var(--inactive-status-box);
      }

      cursor: not-allowed;
    }
  }

  .pdfDocument {
    margin: auto;
    width: max-content;

    .pdfPageLoadingContainer {
      width: 70rem;
      height: 30rem;
      max-width: 100%;
      max-height: 100%;
    }

    canvas {
      margin: auto;
    }
  }

  .pdfDocumentLoading {
    @extend .pdfDocument;
    width: auto;
  }
}

.pdfViewerLoading {
  @extend .pdfViewer;
  min-width: unset;
}
