.cardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  padding: 1rem;
  border-radius: 10px;
}

.cardContainerSecondaryBackground {
  @extend .cardContainer;
  background: var(--section-list-card-second-background);
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 1rem;

  .title {
    font-weight: 600;
    font-size: 0.9rem;
    color: var(--section-list-card-title);
  }

  .description {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--section-list-card-description);

    .heading {
      font-weight: 600;
      color: var(--primary-content);
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    h1 {
      @extend .heading;
      font-size: 2.5rem;
    }

    h2 {
      @extend .heading;
      font-size: 2rem;
    }

    h3 {
      @extend .heading;
      font-size: 1.75rem;
    }

    h4 {
      @extend .heading;
      font-size: 1.5rem;
    }

    h5 {
      @extend .heading;
      font-size: 1.25rem;
    }

    h6 {
      @extend .heading;
      font-size: 1rem;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      margin-bottom: 1rem;
      color: var(--content-description-text);
    }

    blockquote {
      background-color: var(--background);
      border-left: 10px solid var(--content-description-text);
      padding: 1rem;
      margin-bottom: 1rem;

      p {
        margin: 0;
      }
    }

    ul,
    ol {
      padding-left: 2rem;
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--content-description-text);
      margin-bottom: 1rem;
    }

    hr {
      margin-bottom: 1rem;
      background-color: var(--background);
    }

    pre {
      background-color: var(--background);
      color: var(--content-description-text);
      padding: 1rem;
      margin-bottom: 1rem;
      white-space: pre-wrap;
    }

    a {
      margin-bottom: 1rem;
      color: var(--content-description-text);
    }

    table {
      background-color: var(--background);
      margin-bottom: 1rem;
      color: var(--table-cell-text);
      border-collapse: collapse;

      thead {
        tr {
          border-bottom: 1px solid grey;
          th {
            padding: 0.5rem;
          }
        }
      }

      tbody tr td {
        padding: 0.5rem;
      }
    }
  }
}

.sectionCompleted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 0.8rem;

  svg {
    color: var(--primary-content);
    font-size: 4rem;
  }

  span {
    color: var(--primary-content);
    font-size: 0.8rem;
    font-weight: 600;
  }
}
