@import "../../styles/variables";

.container {
  background-color: var(--background-content);
  border-radius: 0.625rem;
  height: 100%;
  padding: 1rem;

  max-width: $layout-breakpoint-xl;
  margin-left: auto;
  margin-right: auto;

  .title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--background-content-text);
    text-transform: uppercase;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    row-gap: 1rem;

    margin-top: 1.5rem; //24px
    margin-bottom: 1rem; //16px

    .searchBar {
      display: flex;
      gap: 1rem;

      .searchButton {
        font-size: 0.75rem;
        font-weight: 500;
      }
    }

    .categoryName {
      display: flex;
      margin-right: auto;
      margin-left: 1rem;
      align-items: center;
      gap: 1rem;
    
      @media screen and (max-width: $layout-breakpoint-sm) {
        flex-direction: column;
        align-items: start;
      }
    
      h1 {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
        color: var(--primary-content);
      }
    
      button {
        display: flex;
        justify-content: center;
        align-items: center;
    
        background: var(--primary-content);
        border: 0.8px solid var(--primary-content);
        border-radius: 5px;
        padding: 0.4rem 0.6rem;
    
        svg {
          color: var(--primary-content-text);
          font-size: 0.8rem;
          font-weight: 500;
        }
    
        transition: filter 0.2s;
    
        &:hover {
          filter: brightness(0.8);
        }
      }
    }

    .addSectionContainer {
      display: flex;
      align-items: stretch;

      .addNewContentButton {
        font-size: 0.75rem;
        font-weight: 700;
      }
    }
  }

  .paginationContainer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
  }
}
