@import "../../../../styles/variables";

.selectContainer {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  width: $modal-content-small-size;
  max-width: 100%;

  span {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--modal-background-text);
  }
}

.changeColorContainer {
  display: flex;
  flex-direction: column;
  width: $modal-content-small-size;
  max-width: 100%;
  margin-top: 1rem;

  .colorsColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 428px) {
      display: flex;
      flex-direction: column;
    }

    fieldset {
      border: none;

      div {
        display: flex;
        align-items: center;

        input {
          border: none;
          background-color: var(--modal-background);
          width: 2rem;
          height: 2rem;
          margin: 0.5rem;
          cursor: pointer;
        }

        input::-webkit-color-swatch-wrapper {
          padding: 0rem;
        }

        input::-webkit-color-swatch {
          border: none;
        }
      }
    }

    span {
      padding-left: 1rem;
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--modal-background-text);
    }
  }
}

.primaryColorTransparent {
  margin: 0.5rem;
  width: 2rem;
  height: 2rem;
  background-color: var(--primary-content-transparent);
}
