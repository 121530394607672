@import '../../../../../../styles/variables';

.courseModalContainer {
  column-gap: 1.5rem;
  row-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    'heading'
    'description'
    'coverImage'
    'footer';

  @media screen and (min-width: $layout-breakpoint-md) {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'heading heading heading heading heading heading heading heading heading'
      'description description description description description coverImage coverImage coverImage coverImage'
      'footer footer footer footer footer footer footer footer footer';
  }

  .heading {
    display: flex;
    flex-wrap: wrap;

    gap: 1rem;
    grid-area: heading;

    @media screen and (min-width: $layout-breakpoint-md) {
      flex-wrap: nowrap;
    }
  }

  .description {
    grid-area: description;
  }

  .coverImage {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    grid-area: coverImage;

    .imageUploadContainer {
      display: flex;
      flex-direction: column;
      column-gap: 1.25rem;

      .imageFileInputContainer {
        display: flex;
        width: 100%;

        .imageFileButton {
          font-size: 0.75rem;
          font-weight: 700;
          white-space: nowrap;

          margin-left: 1rem;
        }
      }
    }

    .imageContentContainer {
      position: relative;

      margin-left: auto;
      margin-right: auto;

      .imageContent {
        max-width: 100%;
        min-height: 300px;
        max-height: 400px;
        object-fit: cover;

        display: block;
        border-radius: 0.625rem;
      }

      .removeImageIcon {
        position: absolute;
        color: white;
        bottom: 1rem;
        right: 1rem;
      }
    }

    .noImageSelectedContainer {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .footer {
    grid-area: footer;
  }
}
