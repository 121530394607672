@import '../../styles/variables';

.backgroundContainer {
  background-image: url('../../assets/images/background_logo.png');
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .authCard {
    background-color: rgba(0, 0, 0, 0.9);
    max-width: 30rem;
    padding: 2.5rem 4.5rem;
    margin-bottom: 4rem;
    border-radius: 10px;
    text-align: center;

    @media screen and (max-width: $layout-breakpoint-sm) {
      margin: 0 1rem 4rem;
      padding: 2.5rem 2rem;
    }

    .imageLogo {
      max-width: 100px;
      object-fit: contain;
      margin-bottom: 45px;
    }

    .textField {
      border-radius: 30px;
      background-color: var(--text-field);
      color: var(--text-field-text);
      font-size: 14px;

      ::placeholder {
        opacity: 1;
        color: var(--primary-content);
      }
    }

    .textFieldIcon {
      color: var(--primary-content);
    }

    .rememberMe {
      display: block;
      margin: 0;
      margin-top: 0.75rem;
      color: white;

      span {
        font-size: 12px;
      }
    }

    .loginButton {
      margin-top: 0.75rem;
      text-transform: capitalize;
      color: white;
      background-color: var(--primary-content);
      font-weight: 400;
      font-size: 1rem;
      border-radius: 30px;

      .loadIndicator {
        margin-left: 0.5rem;
        color: var(--primary-content-text);
      }
    }

    .actionButton {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
      margin: 12px auto auto auto;
      text-decoration: none;
      color: white;

      &:hover {
        color: var(--primary-content);
      }
    }
  }
}
