@import '../../../../styles/variables';

.editContentContainer {
  width: $modal-content-large-size;
  max-width: 100%;
  height: 28rem;
  max-height: 100%;

  .tabLabel {
    font-weight: 600;
    size: 12px;
  }

  .tabPanel {
    padding: 1rem;
  }
}
