.tableHeaderCell {
  font-size: 0.875rem;
  font-weight: 700;
  user-select: none;

  svg {
    display: inline-block;
    cursor: pointer;
    margin-right: 0.25rem;
  }
}

.tableBodyCell {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--table-cell-text);
}

.tableRow:nth-child(odd) {
  background-color: var(--table-cell-odd);
}

.tableRow:nth-child(even) {
  background-color: var(--table-cell-even);
}

.statusBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  font-size: 1rem;
}

.activeBox {
  @extend .statusBox;
  background: var(--active-status-box);
  color: var(--active-status-box-text);
}

.inactiveBox {
  @extend .statusBox;
  background: var(--inactive-status-box);
  color: var(--inactive-status-box-text);
}

.buttonLink {
  text-decoration: underline;
}
