@import '../../../../styles/variables';

.formContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 2rem;

  width: $modal-content-medium-size;
  max-width: 100%;

  @media screen and (min-width: 570px) {
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  }
}

.loadContainer {
  width: $modal-content-medium-size;
  max-width: 100%;
}
