@import "../../styles/variables";

.container {
  background-color: var(--background-content);
  border-radius: 0.625rem;
  height: 100%;
  padding: 1rem;
  
  max-width: $layout-breakpoint-xl;
  margin-left: auto;
  margin-right: auto;

  .title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--background-content-text);
    text-transform: uppercase;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    row-gap: 1rem;

    margin-top: 1.5rem;
    margin-bottom: 1rem;

    .searchBar {
      display: flex;
      gap: 1rem;

      .searchButton {
        font-size: 0.75rem;
        font-weight: 500;
      }
    }

    .addSectionContainer {
      display: flex;
      align-items: stretch;

      .addNewCategoryButton {
        font-size: 0.75rem;
        font-weight: 700;
      }
    }
  }
}

.paginationContainer {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}
