@import '../../../../styles/variables';

.modalContainer {
  height: 25rem;
  max-height: 100%;
  width: $modal-content-large-size;
  max-width: 100%;

  .formContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr) 0.5fr;
    column-gap: 1.5rem;
    row-gap: 1rem;

    .inputContainer {
      border: none;

      .input {
        background-color: var(--white);
      }
    }

    .submitButtonContainer {
      width: 100%;
      display: flex;
      flex-direction: column;

      .submitButton {
        width: 100%;
        height: 100%;
        text-transform: none;
        font-size: 1rem;
      }
    }
  }

  .tableContainer {
    padding: 1rem 0;
  }
}
