@import '../../../../styles/variables';

.container {
    max-width: $layout-breakpoint-xl;
    margin-left: auto;
    margin-right: auto;

  .introductionSkeleton {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $layout-breakpoint-md) {
      flex-direction: row;
    }

    .contentIntroductionBodySkeleton {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: var(--content-description);

      height: 500px;

      .introductionHeadingSkeleton {
        background: var(--primary-content);
        padding: 1rem;

        color: var(--primary-content-text);
        text-transform: uppercase;
      }

      .introductionInfosSkeleton {
        height: 100%;
        padding: 2rem 1rem;
      }

      .introductionActionsSkeleton {
        display: flex;
        justify-content: space-between;
        height: 20%;
        padding: 1rem;
      }
    }
  }

  .sectionsContainerSkeleton {
    margin-top: 3rem;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    h1 {
      font-size: 1.25rem;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--content-description-text);
    }
  }
}
