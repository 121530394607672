.cardContainer {
  overflow: hidden;
  width: 250px;
  background: var(--card-container);
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.cardContent {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.courseName {
  background: var(--primary-content);
  text-align: center;
  padding: 0.5rem 0;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 3rem;
}

.courseDescription {
  font-weight: 400;
  font-size: 0.8rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 9rem;
}