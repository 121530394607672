.container {
  flex: 50%;

  .title {
    display: block;
    margin-bottom: 1rem;
  }

  .tableContainer {
    max-height: 18rem;
    overflow-y: scroll;

    .table {
      table-layout: fixed;

      .tableHeader {
        position: sticky;
        top: 0;
        z-index: 10;

        background-color: var(--modal-save-button);
        .tableHeadCell {
          color: var(--modal-save-button-text);
        }
      }

      td {
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
