@import '../../../../styles/variables';

.selectCompanyContainerMain {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: $modal-content-small-size;
  max-width: 100%;

  .title {
    margin-bottom: 1rem;
  }

  .selectCompanyContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;

    .companyCard {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 1rem;

      padding: 1rem;
      border-radius: 5px;
      background-color: var(--inactive-status-box);
      
      cursor: pointer;
      transition: opacity 300ms ease-in-out;
      
      user-select: none;
    }

    .companyCard:hover {
      opacity: 0.7
    }
  }
}
