.statusBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  font-size: 1rem;
}

.activeBox {
  @extend .statusBox;
  background: var(--active-status-box);
  color: var(--active-status-box-text);
}

.inactiveBox {
  @extend .statusBox;
  background: var(--inactive-status-box);
  color: var(--inactive-status-box-text);
}

.container {
  flex: 50%;

  .title {
    display: block;
    margin-bottom: 1rem;
  }

  .filterContainer {
    display: flex;
    margin: 0 0 1rem 0;

    .input {
      border-radius: 0.313rem;

      flex: 80%;

      input {
        padding: 6px 9px;
      }
    }

    .buttonFilter {
      padding: 7px 26px;
      height: 2.188rem;
      font-size: 12px;
      font-weight: 700;
      box-shadow: none;

      margin-left: 1rem;

      flex: 20%;
    }
  }

  .tableContainer {
    max-height: 18rem;
    overflow-y: scroll;

    .table {
      table-layout: fixed;

      .tableHeader {
        position: sticky;
        top: 0;
        z-index: 10;

        background-color: var(--modal-save-button);
        .tableHeadCell {
          color: var(--modal-save-button-text);
        }
      }

      td {
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
