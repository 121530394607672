.navContainer {
  background: var(--navs);
  height: 100%;
  padding: 2rem;

  .menuItems {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
}
