@import '../../../../styles//variables';

.myListContainer {
  background: var(--background-content);
  border-radius: 10px;
  margin-top: 3rem;
  padding: 1rem;
  color: var(--background-content-text);

  .title {
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: uppercase;
  }

  .noItemsListContainer {
    display: block;
    margin-top: 1rem;
  }

  .listContainer {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    place-items: center;
    gap: 1.5rem;

    @media screen and (max-width: $layout-breakpoint-xl) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: $layout-breakpoint-md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $layout-breakpoint-sm) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .paginationContainer {
    display: flex;
    justify-content: center;

    margin-top: 1.5rem;
  }
}