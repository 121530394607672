@import '../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  width: $modal-content-medium-size;
  max-width: 100%;

  .fileUploadContainer {
    all: unset;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .inputContainer {
      width: 100%;
      display: flex;

      .mediaButton {
        margin-left: 2rem;
        white-space: nowrap;
      }
    }
  }

  .previewEditMediaButtons {
    width: fit-content;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .iframeWrapper {
    display: flex;
    justify-content: center;

    .iframe {
      border: 0;
      max-width: 100%;
    }

    .noVideoContainer {
      width: $modal-content-small-size;
      max-width: 100%;
      margin-top: 1.5rem;

      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      color: var(--modal-background-text);

      p {
        margin-top: 1rem;
        color: var(--modal-background-text);
      }
    }
  }
}

// .selectedFiles {
//   margin-top: -1.5rem;

//   flex: 1;
//   background: var(--primary-50);
//   border-radius: 10px;
//   padding: 1rem;

//   display: flex;
//   flex-direction: column;
//   gap: .5rem;

//   span {
//     display: flex;
//     align-items: center;

//     font-size: .8rem;
//     font-weight: 500;
//     line-height: 1rem;
//     color: var(--primary-400);

//     svg {
//       color: var(--gray-800);
//       font-size: 1.1rem;
//       margin-left: .4rem;

//       cursor: pointer;
//     }
//   }
// }
