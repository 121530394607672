@import '../../../../styles/variables';

.editCategoryContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: $modal-content-medium-size;
  max-width: 100%;

  .tabLabel {
    font-weight: 600;
    size: 12px;
  }

  .tabPanel {
    padding: 1rem;

    .formContainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
