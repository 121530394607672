@import '../../../../styles/variables';

.selectContainerMain {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: $modal-content-small-size;
  max-width: 100%;

  .emailTitle {
    margin-bottom: 1rem;
  }

  .selectContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
  }

  .ContainerButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    button {
      border: none;
      background: var(--modal-save-button);
      border-radius: 5px;
      padding: 0.7rem 1.5rem;

      color: var(--modal-save-button-text);
      font-weight: 700;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
}
