@import '../../../../styles/variables';

.selectContainerMain {
  display: flex;
  justify-content: center;

  .selectContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    max-width: 100%;
    width: $modal-content-small-size;
  }
}
