.menuContainer {
  padding: 1rem;
  max-height: 20rem;
  width: 31rem;
  max-width: 100%;

  h5 {
    color: var(--primary-content);
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .loadBox {
    margin: 1rem 0;
  }

  .categoriesList {
    margin: 1rem 0;

    li {
      margin-bottom: 0.6rem;

      color: var(--navs-text);
      font-weight: 500;
      font-size: 0.9rem;
      white-space: break-spaces;
    }

    .selectedItem {
        color: var(--primary-content) !important;
        font-weight: 600 !important;
      }
  }

  .paginationContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }
}
