.modalBackground {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  .modalContainer {
    background: var(--modal-background);
    border-radius: 10px;
    overflow: auto;

    max-width: 75rem;
    max-height: 100%;

    display: flex;
    flex-direction: column;

    .modalHeading {
      position: relative;
      padding: 2rem;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      cursor: move;

      display: flex;

      background: var(--primary-content);

      h1 {
        color: var(--primary-content-text);
        font-size: 1.25rem;
        font-weight: 600;
        text-transform: uppercase;
        user-select: none;
      }

      button {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;

        svg {
          color: var(--primary-content-text);
          font-size: 1.5rem;
        }
      }
    }

    .modalButtonRow {
      display: flex;
      justify-content: flex-end;
    }

    .modalContent {
      padding: 1rem;
      height: 100%;
      overflow-y: auto;
    }

    .modalActions {
      display: flex;
      justify-content: flex-end;
      padding: 1rem;

      button {
        border: none;
        background: var(--modal-save-button);
        border-radius: 5px;
        padding: 0.7rem 1.5rem;

        color: var(--modal-save-button-text);
        font-weight: 700;

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }
}

.modalBackground.fullscreen {
  padding: 0;

  .modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;

    max-width: none;
    width: 100%;
    border-radius: 0;

    transform: none!important;

    .modalHeading {
        border-radius: 0;
        cursor: auto;
    }
  }
}
