* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  scrollbar-width: thin;
  scrollbar-color: var(--primary-content) transparent;
}

:root {
  --primary-color: #e13e00;
  --primary-color-transparent: #e13e0099;
  --primary-color-secondary-transparent: #e13e0014;
  --secondary-color: #ffdcc8;
  --header-color: #ff6d2a;
}

:root[theme='light'] {
  --primary-content: var(--primary-color);
  --primary-content-text: #ffffff;
  --primary-content-transparent: var(--primary-color-transparent);

  --header: var(--header-color);
  --header-text: #ffffff;

  --text-field: #ffffff;
  --text-field-text: #000000;

  --background: #ffffff;
  --background-content: #f5f5f5;
  --background-content-text: #231f20;

  --modal-background: #ffffff;
  --modal-background-text: #231f20;
  --modal-save-button: #231f20;
  --modal-save-button-text: #ffffff;

  --card-container: #ffffff;
  --card-title: var(--secondary-color);
  --card-description: #231f20;
  --card-actions: var(--primary-content);
  --card-completed-svg: #ffffff;

  --section-list-card-second-background: var(--primary-color-secondary-transparent);
  --section-list-card-title: var(--primary-content);
  --section-list-card-description: #231f20;

  --content-description: #ffffff;
  --content-description-text: #231f20;

  --footer: #111111;
  --footer-text: #ffffff;

  --navs: #ffffff;
  --navs-text: #231f20;

  --inactive-status-box: #c4c4c4;
  --inactive-status-box-text: #231f20;

  --active-status-box: var(--secondary-color);
  --active-status-box-text: var(--primary-content);

  --table-cell-odd: #ffffff;
  --table-cell-even: #f5f5f5;
  --table-cell-text: #000000;

  --pagination-icon-outline: #231f20;
  --pagination-icon-fill: #231f20;
  --ellipsis-pagination: #000000;
}

:root[theme='dark'] {
  --primary-content: var(--primary-color);
  --primary-content-text: #ffffff;
  --primary-content-transparent: var(--primary-color-transparent);

  --header: var(--header-color);
  --header-text: #ffffff;

  --text-field: #ffffff;
  --text-field-text: #000000;

  --background: #231f20;
  --background-content: #111111;
  --background-content-text: #ffffff;

  --modal-background: #ffffff;
  --modal-background-text: #231f20;
  --modal-save-button: #231f20;
  --modal-save-button-text: #ffffff;

  --card-container: #1a1718;
  --card-title: var(--secondary-color);
  --card-description: #ffffff;
  --card-actions: var(--secondary-color);
  --card-completed-svg: #ffffff;

  --section-list-card-second-background: #111111;
  --section-list-card-title: var(--primary-content);
  --section-list-card-description: #ffffff;

  --content-description: #111111;
  --content-description-text: #ffffff;

  --footer: #111111;
  --footer-text: #ffffff;

  --navs: #111111;
  --navs-text: #ffffff;

  --inactive-status-box: #c4c4c4;
  --inactive-status-box-text: #231f20;

  --active-status-box: var(--secondary-color);
  --active-status-box-text: var(--primary-content);

  --table-cell-odd: #1a1718;
  --table-cell-even: #231f20;
  --table-cell-text: #ffffff;

  --pagination-icon-outline: var(--secondary-color);
  --pagination-icon-fill: var(--secondary-color);
  --ellipsis-pagination: #ffffff;
}

body,
input,
textarea,
select,
button {
  font-family: 'Montserrat', sans-serif;
}

body {
  background-color: var(--background);
}

button {
  all: unset;
  cursor: pointer;
}

ul,
ol {
  list-style-position: inside;
}

.MuiInputBase-root {
  background-color: var(--text-field);
}

.MuiPagination-ul {
  li {
    .MuiPaginationItem-page {
      border: 1px solid var(--primary-content);
      color: var(--pagination-icon-outline);
    }

    .MuiPaginationItem-previousNext {
      border: 1px solid var(--pagination-icon-outline);

      svg {
        fill: var(--pagination-icon-fill);
      }
    }

    .Mui-selected {
      background-color: var(--primary-color-transparent);
    }
  }
}

.MuiPaginationItem-ellipsis {
  color: var(--ellipsis-pagination);
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-content);
}
