.tableHeaderCell {
  font-size: 0.875rem;
  font-weight: 700;
  user-select: none;

  svg {
    display: inline-block;
    cursor: pointer;
    margin-right: 0.25rem;
  }
}

.tableBodyCell {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--table-cell-text);
}

.tableRow:nth-child(odd) {
  background-color: var(--table-cell-odd);
}

.tableRow:nth-child(even) {
  background-color: var(--table-cell-even);
}
