@import '../../styles/variables';

.videoContainer {
  display: flex;
  flex-direction: column;

  .videoTitle {
    text-align: left;
    font-weight: 500;
    color: var(--modal-background-text);
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
  }

  .iframe {
    border: 0;
    max-width: 100%;
  }

  .noVideoContainer {
    width: $modal-content-small-size;
    max-width: 100%;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    color: var(--modal-background-text);

    p {
      margin-top: 1rem;
      color: var(--modal-background-text);
    }
  }
}
