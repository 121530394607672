@import '../../styles/variables';

.modalBackground {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .modalContainer {
    background: var(--modal-background);
    border-radius: 10px;

    max-width: $modal-content-small-size;
    max-height: 56rem;
    width: 100%;
    margin: 0 1rem;

    .modalHeading {
      position: relative;
      padding: 2rem;

      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      display: flex;

      background: var(--primary-content);

      h1 {
        color: var(--primary-content-text);
        font-size: 1.25rem;
        font-weight: 600;
        text-transform: uppercase;
      }

      button {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;

        border: none;
        background: transparent;

        svg {
          color: var(--primary-content-text);
          font-size: 1.5rem;
        }
      }
    }

    .content {
      padding: 2rem;

      .message {
        color: var(--modal-background-text);
        font-weight: 500;
      }
    }

    .modalActions {
      display: flex;
      justify-content: flex-end;
      padding: 2rem;
      gap: 1rem;
    }
  }
}
